/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import '~@ng-select/ng-select/themes/default.theme.css';
.tbo-custom-container {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media only screen and (min-width: 576px) {
  .tbo-custom-container {
    max-width: 33.75rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 768px) {
  .tbo-custom-container {
    max-width: 45rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 992px) {
  .tbo-custom-container {
    max-width: 81.875rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.form-control {
  @apply block w-full h-10 bg-white border border-[#D0D5DD] rounded placeholder:text-[#1C2534] focus:border-green-400 read-only:bg-[#A0ADFF54] mt-1 placeholder:text-base placeholder:font-normal  text-black px-3.5 outline-0;
}

.textarea-control {
  @apply block w-[calc(100%-32px)] bg-white border border-[#D0D5DD] rounded placeholder:text-[#1C2534] focus:border-green-400 px-4 py-3 read-only:bg-[#A0ADFF54] mt-1 placeholder:text-base placeholder:font-normal  text-black outline-0;
}

.search-control {
  @apply min-w-90 outline-none bg-[#F7F7F7] text-sm text-[#636363] pl-10 pr-6 md:pr-6 placeholder:text-[#636363] border-2 border-[#E8E8E8] rounded-md py-3;
}

.input-error {
  @apply text-red-500 text-sm mt-1;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-mdc-form-field-infix {
  min-height: 2.5rem;
}

.btn {
  @apply flex items-center gap-x-2 rounded border border-[#6067A3] text-[#6067A3] py-3 px-10 bg-inherit disabled:opacity-50;
}

.mat-mdc-progress-spinner {
  /** add custom colors for spinner below */
}
.mat-mdc-progress-spinner.spinner-white {
  --mdc-circular-progress-active-indicator-color: #ffffff !important;
}

.c-tasks-icons {
  @apply z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1;
}

.border-l-survey {
  border-left: 0.125rem solid #a18e43 !important;
}

.border-l-outbound {
  border-left: 0.125rem solid #c5aae9;
}

.border-l-hotel {
  border-left: 0.125rem solid #b3f4cb !important;
}

.border-l-air-ticket {
  border-left: 0.125rem solid #98dce5;
}

.border-l-receipt {
  border-left: 0.125rem solid #de965f;
}

.border-l-vip {
  border-left: 0.125rem solid #fcd533;
}

.border-l-inbound {
  border-left: 0.125rem solid #98dce5;
}

.modal-width .mat-dialog-container {
  max-width: 92rem !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill {
  width: 100% !important;
}

.mdc-floating-label--required::after {
  color: #ff0000 !important;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.theme-brand .light .mat-expansion-panel:not([class*=mat-elevation-z]),
.theme-brand.light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  border-bottom: 0.0625rem solid #d0d5dd !important;
}

.mat-accordion .mat-expansion-panel {
  border-radius: 3px !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-mdc-menu-panel .mat-mdc-menu-content {
  background: #ffffff !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 2.5rem !important;
}

.ng-select .ng-select-container {
  border-radius: 0.25rem !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: rgba(160, 173, 255, 0.3294117647) !important;
}

.mat-expansion-panel-content {
  overflow-y: auto !important;
}

.ng-select.ng-select-opened .ng-select-container {
  border: 0.0625rem solid rgb(74, 222, 128) !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border: 0.0625rem solid rgb(74, 222, 128) !important;
}